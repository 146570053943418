import React, { Fragment } from 'react';

import formatPrice from '../../../../../helpers/formatPrice';
import { useBasketContext } from '../../../../../data/context/basketContext/basketContext';

import Typography from '../../../../_ui/_blocks/Typography/Typography';

const AMOUNT_FOR_FREE_DELIVERY = 4000;

/**
 * Loading component to render server side. Should match the look of the component below.
 */
export const FreeDeliveryNoticeSsr = () => (
    <Fragment>
        <Typography
            as="div"
            weight="700"
            size="090"
            lineHeight="200"
            className="w-[17.25rem] h-[1.125rem] skeleton-animation"
        />
        <Typography
            as="div"
            color="quiet"
            size="080"
            className="w-[12.75rem] h-[1rem] mt-025 skeleton-animation"
        />
    </Fragment>
)

/**
 * Displays a heading and description of how much is left till free delivery or if they already have free
 * delivery.
 */
const FreeDeliveryNotice = () => {
    // @ts-expect-error
    const { basket } = useBasketContext();

    const amountRemainingForFreeDelivery = AMOUNT_FOR_FREE_DELIVERY - (basket?.total || 0);
    const qualifiesForFreeDelivery = amountRemainingForFreeDelivery <= 0;

    const freeDeliveryHeading = qualifiesForFreeDelivery ? 'Includes free standard delivery' : 'Free standard delivery on orders over £40';

    const freeDeliveryDescription = qualifiesForFreeDelivery
        ? 'Your order qualifies for free delivery!'
        : `Spend ${formatPrice(amountRemainingForFreeDelivery)} more for free delivery`;

    return (
        <Fragment>
            <Typography as="div" weight="700" size="090" lineHeight="200">{freeDeliveryHeading}</Typography>
            <Typography as="div" color="quiet" size="080">{freeDeliveryDescription}</Typography>
        </Fragment>
    );
};

export default FreeDeliveryNotice;
